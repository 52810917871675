import "./Audiobook.css";
import React, {Fragment} from "react";

const Audiobook = (props) => {
    let data = props.data;
    const isLoggedIn = !!props.user && !!props.user.username;
    let ownsBook = false;
    let bookmark = 0;
    console.log('props', props)
    console.info('isLoggedIn' + isLoggedIn)
    if (isLoggedIn && !!props.user.audiobooks && props.user.audiobooks.length > 0) {
        for (let index = 0; index < props.user.audiobooks.length; index++) {
            let book = props.user.audiobooks[index];
            console.info(book)
            console.info(props.data)
            if (props.data.listenAudiobookLink) {
                if (props.data.id == book.name) {
                    ownsBook = true;
                    try {
                        bookmark = parseInt(book.bookmark);
                    } catch (exception) {
                        console.error(exception);
                    }
                    break;
                }
            }
        }
    }

    let digitalSnippet = <Fragment/>;
    if (data.listenAudiobookLink /**|| data.buyAudiobookLink**/) {

        console.info("Owns book ? " + ownsBook)
        digitalSnippet = ownsBook ?
            <span className={"DashboardBook-Right"}>
                <a href={data.listenAudiobookLink + "/" + bookmark}>
                    <div className={"DashboardBook-Listen"}>
                        <div>Listen Now</div>
                        <div>Owned</div>
                    </div>
                </a>
            </span>
            :
            <Fragment />;
            // <span className={"DashboardBook-Right"}>
            //     <a href={data.buyAudiobookLink}>
            //         <div className={"DashboardBook-Buy"}>
            //             <div>Online Audiobook</div>
            //             <div>Buy Now</div>
            //         </div>
            //     </a>
            // </span>;

    }

    return (<span id={data.id} className={"DashboardBook"}>
        <span>
            <img className={"DashboardBook-Cover"} src={data.coverImage}></img>
        </span>
        <span className={"DashboardBook-Text"}>
            <h2>{data.title}</h2>
            <div dangerouslySetInnerHTML={{__html: data.description}}></div>
        </span>
        <span className={"DashboardBook-Column"}>
            {digitalSnippet}
        </span>
        </span>);
}

export default Audiobook;
