import "./About.css";
import React from "react";

const About = (props) => {

    return <span className={"About"}>
            <img className={"About-Image"} src={props.image}></img>
            <span className={"About-Text"}>
            <p>{props.description}</p>
            </span>
    </span>;
}

export default About;
