import "./ReaderControls.css";
import React, {Fragment, useEffect, useState} from "react";
import {Link, NavLink, useParams} from "react-router-dom";
import {BookListing} from "../../Books/Books";

const ReaderControls = (props) => {
    const user = props.user;
    const book = props.book;
    let page = props.page;
    let maxChapter = 0;
    let minChapter = 0;

    if (!!book) {
        let bookListingEntries = Object.entries(BookListing);
        for (let bookIndex = 0; bookIndex < bookListingEntries.length; bookIndex++) {
            const [key, value] = bookListingEntries[bookIndex];

            if (value.readEbookLink) {
                if (value.readEbookLink.indexOf(book) >= 0) {
                    maxChapter = value.chapters;
                }
            }
        }
    }

    page = parseInt(page)

    let previousChapterText = <span className={"ReaderControls-Chapter-Control"}>فصل قبلی</span>;
    let previousChapter = page > minChapter ?
        <h1><NavLink className={"ReaderControls-Control"}
                     to={"/reader/" + book + "/" + (page - 1)}>{previousChapterText}<img
            className={"ReaderControls-Icon"} src="/back_arrow.svg"></img></NavLink></h1> :
        <div className={"ReaderControls-PlaceHolder"}>{previousChapterText}</div>;

    let characters = <h1><NavLink className={"ReaderControls-Control"} to={"/reader/" + book + "/-1"}><img
        className={"ReaderControls-Icon"} src="/camera.svg"></img></NavLink></h1>;


    let nextChapterText = <span className={"ReaderControls-Chapter-Control"}>فصل بعدی</span>;
    let nextChapter = page + 1 <= maxChapter ?
        <h1><NavLink className={"ReaderControls-Control"} to={"/reader/" + book + "/" + (page + 1)}><img
            className={"ReaderControls-Icon"} src="/next_arrow.svg"></img>{nextChapterText}</NavLink></h1> :
        <div className={"ReaderControls-PlaceHolder"}>{nextChapterText}</div>;

    return <span className={"ReaderControls"}>
        <h1 className={"ReaderControls-Navigation"}><NavLink to={"/dashboard"}><img className={"ReaderControls-Icon"}
                                                                                    src="/back_icon.svg"></img></NavLink></h1>
        <div className={"ReaderControls-Content"}>
        {previousChapter}
            {characters}
            {nextChapter}
        </div>
    </span>;
}

export default ReaderControls;
