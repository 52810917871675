console.log("IndexedDBUtil loaded")
class IndexedDBUtil {
    constructor(dbName, storeName) {
        this.dbName = dbName;
        this.storeName = storeName;
        this.db = null;
    }

    // Initialize the database
    async init() {
        if (this.db) {
            return this.db;
        }

        return new Promise((resolve, reject) => {
            const request = indexedDB.open(this.dbName, 1);

            request.onupgradeneeded = (event) => {
                const db = event.target.result;
                if (!db.objectStoreNames.contains(this.storeName)) {
                    db.createObjectStore(this.storeName, { keyPath: "url" });
                }
            };

            request.onsuccess = (event) => {
                this.db = event.target.result;
                resolve(this.db);
            };


            request.onerror = (event) => {
                console.error("IndexedDB error:", event.target.error);
                reject(event.target.error);
            };
        });
    }

    // Add or update a record in the store
    async put(record) {
        const db = await this.init();
        return new Promise((resolve, reject) => {
            const transaction = db.transaction([this.storeName], "readwrite");
            const store = transaction.objectStore(this.storeName);
            const request = store.put(record);

            request.onerror = () => reject(request.error);
            request.onsuccess = () => resolve(request.result);
        });
    }

    // Retrieve a record by its key
    async get(key) {
        const db = await this.init();
        return new Promise((resolve, reject) => {
            const transaction = db.transaction([this.storeName], "readonly");
            const store = transaction.objectStore(this.storeName);
            const request = store.get(key);

            request.onerror = () => reject(request.error);
            request.onsuccess = () => resolve(request.result);
        });
    }

    // Delete a record by its key
    async delete(key) {
        const db = await this.init();
        return new Promise((resolve, reject) => {
            const transaction = db.transaction([this.storeName], "readwrite");
            const store = transaction.objectStore(this.storeName);
            const request = store.delete(key);

            request.onerror = () => reject(request.error);
            request.onsuccess = () => resolve(request.result);
        });
    }


    // Convenience method to fetch from network and cache or return cached content
    async fetchOrGet(key, fetchUrl) {
        try {
            // Try to get the content from the database
            const record = await this.get(key);
            if (record) {
                console.log('Returning cached content');
                return URL.createObjectURL(record.blob); // Return a URL for the Blob if found
            } else {
                // If not found in the database, fetch from the network
                console.log('Fetching content from the network');
                const response = await fetch(fetchUrl);
                const blob = await response.blob();
                // Store the fetched content in the database
                await this.put({ url: key, blob: blob });
                // Return the content
                return URL.createObjectURL(blob);
            }
        } catch (error) {
            console.error('Error in fetchOrGet:', error);
            throw error;
        }
    }
}

export default IndexedDBUtil