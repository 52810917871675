import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import H5AudioPlayer from "react-h5-audio-player";
import {BookListing} from "../Books/Books";
import {useAuth} from "../AuthContext";
import "./Listen.css"

const ChaptersPanel = ({chapters, onSelectChapter}) => {
    return (<div className="ChaptersPanel">
            <button onClick={() => onSelectChapter(null)}>Close</button>
            <h2>Chapters</h2>
            {chapters.map((chapter, index) => (<div key={index} onClick={() => onSelectChapter(index)}>
                    Chapter {index + 1}
                </div>))}
        </div>);
};

const Listen = () => {
    const playerRef = useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(0);

    const [showChapters, setShowChapters] = useState(false);

    let currentTrackString = localStorage.getItem('currentTrack');
    let initialTrack = 0;
    if (currentTrackString) {
        const savedTrack = JSON.parse(currentTrackString);
        if (savedTrack && typeof savedTrack.index === "number") {
            initialTrack = savedTrack.index;
        }
    }

    const [currentTrack, setTrackIndex] = useState(initialTrack);
    const [currentSrc, setCurrentSrc] = useState('');
    const {book} = useParams();
    const {authenticated, user} = useAuth();
    // const dbUtil = useIndexedDB(); // Use the IndexedDB utility

    let bookListingEntries = Object.entries(BookListing);
    let bookItem = null;
    for (let bookIndex = 0; bookIndex < bookListingEntries.length; bookIndex++) {
        const [key, value] = bookListingEntries[bookIndex];
        if (book === value.id) {
            bookItem = value;
            break;
        }
    }

    let bookChaptersString = localStorage.getItem(`${bookItem.id}-chapters`);
    let bookChapters = [];
    if (bookChapters) {
        bookChapters = JSON.parse(bookChaptersString)
    }
    const [chapters, setChapters] = useState(bookChapters)

    const savePlaybackTime = () => {

        const currentTime = playerRef.current?.audio.current?.currentTime || 0;

        let currentTrackValue = localStorage.getItem('currentTrack');
        let currentSavedTime = !!currentTrackValue? JSON.parse(currentTrackValue) : 0;
        if (currentSavedTime != 0 && currentTime == 0) {
            return;
        }
        localStorage.setItem('currentTrack', JSON.stringify({index: currentTrack, time: currentTime}));
    };

    useEffect(() => {
        const savedTrack = JSON.parse(localStorage.getItem('currentTrack'));
        if (savedTrack) {
            setTrackIndex(savedTrack.index);
            // Seeking to the saved time will be done after the track is loaded
        }
    }, []);

    const updateTrack = async () => {
        if (!chapters || chapters.length === 0) {
            return;
        }
        setIsLoading(true);
        setLoadingProgress(0); // Reset progress on new load
        const trackUrl = chapters[currentTrack];

        console.info(`Fetching...${trackUrl}`)
        const response = await fetch(trackUrl);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const contentLength = response.headers.get('content-length');
        if (contentLength === null) {
            throw new Error("Failed to get content length");
        }

        const reader = response.body.getReader();
        let receivedLength = 0; // received that many bytes at the moment
        let chunks = []; // array of received binary chunks (comprises the body)
        while(true) {
            const {done, value} = await reader.read();

            if (done) {
                break;
            }

            chunks.push(value);
            receivedLength += value.length;

            setLoadingProgress((receivedLength / contentLength) * 100); // Update progress
        }

        const chunksAll = new Uint8Array(receivedLength); // (4.1)
        let position = 0;
        for(let chunk of chunks) {
            chunksAll.set(chunk, position); // (4.2)
            position += chunk.length;
        }

        const audioBlob = new Blob([chunksAll], {type: "audio/mp4"});
        const audioBlobUrl = URL.createObjectURL(audioBlob);
        setCurrentSrc(audioBlobUrl);
        setIsLoading(false);
        console.info(`Fetched...${trackUrl}`)
    }

    const checkBookChapters = async () => {
        if (!chapters || chapters.length == 0) {
            fetch("/book.json")
                .then((response) => {
                    return response.json();
                }).then(async (data) => {
                let shouldUpdate = true;
                if (chapters) {
                    shouldUpdate = JSON.stringify(chapters) !== JSON.stringify(data.chapters);
                }

                if (shouldUpdate) {
                    localStorage.setItem(`${bookItem.id}-chapters`, JSON.stringify(data.chapters));
                    setChapters(data.chapters)
                }
            });
        }
    }

    useEffect(() => {
       checkBookChapters().then()

    }, [bookItem, currentTrack]);

    useEffect(() => {
        updateTrack().then()
    }, [chapters, currentTrack]);

    const isLoggedIn = authenticated && !!user && !!user.username;

    const onSelectChapter = (chapterIndex) => {
        if (chapterIndex !== null) {
            setTrackIndex(chapterIndex);
        }
        setShowChapters(false);
    };

    const handleTimeUpdate = () => {
        savePlaybackTime();
    };
    const handleClickNext = () => {
        if (playerRef.current) {
            playerRef.current.audio.current.pause();
        }
        setTrackIndex(currentTrack => currentTrack < chapters.length - 1 ? currentTrack + 1 : 0);
    };
    const handleClickPrevious = () => {
        if (playerRef.current) {
            playerRef.current.audio.current.pause();
        }
        setTrackIndex(currentTrack => currentTrack > 0 ? currentTrack - 1 : chapters.length -1);
    };

    const handleEnd = () => {
        setTrackIndex(currentTrack => currentTrack < chapters.length - 1 ? currentTrack + 1 : 0);
    };

    let headerSnippet = <span className={"AudioPlayer-Header"}>
        <span>
            <img className={"DashboardBook-Cover"} src={bookItem.coverImage}></img>
        </span>
                <span className={"AudioPlayer-Chapter"}>
            Chapter {currentTrack + 1}
        </span>
        </span>;

    let footerSnippet = <span className={"AudioPlayer-Footer"}>
        <button onClick={() => setShowChapters(true)}>
            <span className={"Listen-Chapters-Icon"}>☰</span>
            <span className={"Listen-Chapters-Text"}>Chapters</span>
        </button>
    </span>;

    if (!isLoggedIn) return <Fragment/>;

    if (isLoading) {
        const radius = 50; // Radius of the circle
        const circumference = 2 * Math.PI * radius; // Circumference of the circle
        const progress = loadingProgress; // Assume loadingProgress is from 0 to 100
        const strokeDashoffset = circumference * (1 - progress / 100);

        return (
            <div className="LoadingOverlay">
                <svg height="120" width="120">
                    <circle
                        cx="60"
                        cy="60"
                        r={radius}
                        stroke="rgba(190,190,190, 0.8)" // Changed to a lighter gray to fit the theme
                        strokeWidth="4"
                        fill="transparent"
                        strokeDasharray={circumference}
                        strokeDashoffset={strokeDashoffset}
                        transform="rotate(-90 60 60)" // Rotate to start progress from the top
                    />
                    <text x="50%" y="50%" textAnchor="middle" stroke="rgba(190,190,190, 0.8)" strokeWidth="1px" dy=".3em" fill="rgba(190,190,190, 0.8)">
                        {`${Math.round(progress)}%`}
                    </text>
                </svg>
                <div>Loading audio...</div>
            </div>
        );
    }

    return (<div className={"Listen"}>
        <H5AudioPlayer
            ref={playerRef}
            className={"ListenAudioPlayer"}
            // header={<img className={"DashboardBook-Cover"} src={bookItem.coverImage} alt="Book Cover"/>}
            header={headerSnippet}
            // footer={<button onClick={() => setShowChapters(true)}>Chapters</button>}
            footer={footerSnippet}
            src={currentSrc}
            customAdditionalControls={[]}
            showJumpControls={true}
            showDownloadProgress={true}
            showFilledProgress={true}
            showFilledVolume={true}
            showSkipControls={true}
            loop={false}
            onClickPrevious={handleClickPrevious}
            onClickNext={handleClickNext}
            onEnded={handleEnd}
            onError={(error) => console.debug('play error', error)}
            volume={1.0}
            autoPlay={true}
            onListen={handleTimeUpdate}
            onLoadedData={() => {
                let currentTrackString = localStorage.getItem('currentTrack');
                if (currentTrackString) {
                    const savedTrack = JSON.parse(currentTrackString);
                    if (savedTrack && savedTrack.index === currentTrack) {
                        playerRef.current.audio.current.currentTime = savedTrack.time;
                    }
                }
            }}
            onLoadedMetadata={() => {

            }}
        />
        {showChapters && <ChaptersPanel chapters={chapters} onSelectChapter={onSelectChapter}/>}
    </div>);
};

export default Listen;
