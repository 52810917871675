import './App.css';
import saba from "./Images/saba.jpg";
import Header from "./Header/Header";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {Books} from "./Books/Books";
import About from "./About/About";
import Home from "./Home/Home";


// import {createBrowserHistory} from "history";
import Footer from "./Footer/Footer";
import React, {useEffect, useState} from "react";
import EbookPage from "./EbookPage/EbookPage";
import Reader from "./Reader/Reader";
import Login from "./Login/Login";
import Error from "./Error/Error";
import BarcodeGenerator from "./BarcodeGenerator/BarcodeGenerator";
import EmailCapture from "./EmailCapture/EmailCapture";
import AudiobookPage from "./AudiobookPage/AudiobookPage";
import Keycloak from 'keycloak-js';
import {AuthProvider} from './AuthContext';
import Listen from "./Listen/Listen";
import {IndexedDBProvider} from "./IndexedDBContext";


const App = (props) => {

    const [user, setUser] = useState({});
    const [keycloakState, setKeycloakState] = useState({
        keycloak: null,
        authenticated: false
    });

    useEffect(() => {

    }, []);

    const displayUsername = !!user && !!user.username ? user.username : "No username";
    const isLoggedIn = !!user && !!user.username;
    return (
        <div className="App">
            <AuthProvider>
                <IndexedDBProvider>
                    <Router>
                        <Header user={user}/>
                        <div className={"App-Content"}>
                            <Routes>
                                <Route path={"/ebook"} element={<EbookPage user={user}/>}>
                                </Route>
                                <Route path={"/audiobook"} element={<AudiobookPage user={user}/>}>
                                </Route>
                                {/*<Route history={customHistory} path={"/about"}>*/}
                                <Route path={"/about"} element={<About
                                    description={"I'm an architect who is in love with writing and storytelling. " +
                                        "Based in Washington, D.C., I am crafty and love to do projects " +
                                        "and to create something unique! In my saga, NovelsBySaba, each book features an accompanying song and illustrations to better accommodate the readers' imaginations. " +
                                        "From early on, I had dreamed of writing books inspired by true stories that readers would fall in love with." +
                                        "\n" +
                                        "Thanks to all of you for your support.\n" +
                                        "I wish you the best of luck.\n" +
                                        "Saba"} image={saba}/>}>
                                </Route>
                                <Route path={"/books"} element={<Books user={user}/>}>
                                </Route>
                                <Route path={"/login"} element={<Login/>}>
                                </Route>
                                <Route path={"/listen/:book/:bookmark"}
                                       element={<Listen user={user} setUser={setUser}/>}>
                                </Route>
                                <Route path={"/listen/:book"} element={<Listen user={user} setUser={setUser}/>}>
                                </Route>
                                <Route path={"/reader/:book/:page"} element={<Reader user={user} setUser={setUser}/>}>
                                </Route>
                                <Route path={"/reader/:book"} element={<Reader user={user} setUser={setUser}/>}>
                                </Route>
                                <Route path={"/barcode-generator"} element={<BarcodeGenerator/>}>
                                </Route>
                                <Route path={"/email-capture"} element={<EmailCapture/>}>
                                </Route>
                                <Route path={"/error/:error"} element={<Error user={user} setUser={setUser}/>}>
                                </Route>
                                <Route path={"/error/"} element={<Error user={user} setUser={setUser}/>}>
                                </Route>
                                <Route path={"/home"} element={<Home/>}>
                                </Route>
                                <Route path={"/"} element={<Home/>}>
                                </Route>
                            </Routes>
                        </div>
                        <Footer/>
                    </Router>
                </IndexedDBProvider>
            </AuthProvider>
        </div>
    );
}

export default App;
