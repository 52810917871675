import React, {Fragment, useEffect} from "react";
import {useAuth} from "../AuthContext";


const Login = (props) => {
    const { keycloak, authenticated, login } = useAuth();

    const searchParams = new URLSearchParams(window.location.search);
    const redirectUri = searchParams.get('redirect');

    useEffect(() => {
        if (keycloak && !authenticated) {
            login()
        } else if (keycloak && authenticated) {
            console.info("keycloak")
            console.info(keycloak)
            console.info(authenticated)
            if (redirectUri) {
                window.location.href = redirectUri
            }
        }
    }, [keycloak, authenticated]);


    return <Fragment />;
}

export default Login;