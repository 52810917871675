import React, { createContext, useContext, useState, useEffect } from 'react';
import Keycloak from 'keycloak-js';

const AuthContext = createContext({
    user: {},
    keycloak: null,
    initialized: false,
    authenticated: false,
    setInitialized: () => {},
    setUser: () => {},
    setKeycloakState: () => {},
    logout: () => {},
    login: () => {},
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [state, setState] = useState({
        user: {},
        initialized: false,
        keycloak: null,
        authenticated: false,
    });

    useEffect(() => {
        // Attempt to use cached auth info if available
        const cachedUser = localStorage.getItem('user');
        // const cachedAuthenticated = localStorage.getItem('authenticated') === 'true';

        // if (cachedUser && cachedAuthenticated) {
        if (cachedUser) {
            setState(prevState => ({
                ...prevState,
                user: JSON.parse(cachedUser),
                // authenticated: cachedAuthenticated,
                authenticated: true,
                initialized: true, // Assume initialization is complete if cached data is used
            }));
            return;
        }

        const keycloak = new Keycloak({
            url: 'https://novelsbysaba.com/auth',
            realm: 'novelsbysaba',
            clientId: 'nbs-web',
        });

        keycloak.init({ onLoad: 'check-sso' }).then(authenticated => {
            setState(prevState => ({
                ...prevState,
                keycloak: keycloak,
                authenticated: authenticated,
            }));

            if (authenticated) {
                fetch("https://novelsbysaba.com/novelsbysaba/me", {
                    credentials: "include",
                    mode: "cors",
                    redirect: "follow",
                    headers: {
                        "accept": "*/*"
                    }
                })
                    .then((response) => response.json())
                    .then((result) => {
                        if (result && result.username) {
                            setUser(result);
                        }
                    })
                    .catch((error) => {
                        console.log(`Error fetching user details: ${JSON.stringify(error)}`);
                        // Use cached user if fetch fails
                        let cachedUser = localStorage.getItem('user');
                        if (cachedUser) {
                            setUser(JSON.parse(cachedUser));
                        }

                        if (cachedUser) {
                            setState(prevState => ({
                                ...prevState,
                                user: cachedUser,
                                initialized: true,
                            }));
                        }

                    })
                    .finally(() => setInitialized(true));
            } else {
                setInitialized(true);
            }
        }).catch(error => {
            console.error("Authentication error: ", error);
            setInitialized(true);
            // Use cached state if Keycloak init fails
            let cachedUser = localStorage.getItem('user');
            let cachedAuthenticated = localStorage.getItem('authenticated') === 'true';

            if (cachedUser && cachedAuthenticated) {
                setState(prevState => ({
                    ...prevState,
                    user: cachedUser,
                    authenticated: cachedAuthenticated,
                    initialized: true,
                }));
            }
        });
    }, []);

    const setInitialized = (initialized) => {
        setState(prevState => ({
            ...prevState,
            initialized: initialized,
        }));
    };

    const setUser = (user) => {
        setState(prevState => ({
            ...prevState,
            user,
        }));
        localStorage.setItem('user', JSON.stringify(user));
    };

    const setKeycloakState = (keycloak, authenticated) => {
        setState(prevState => ({
            ...prevState,
            keycloak,
            authenticated,
        }));
        localStorage.setItem('authenticated', authenticated);
    };

    const logout = () => {
        fetch('/novelsbysaba/logout')
            .then(() => {
                if (state.keycloak) {
                    state.keycloak.logout({
                        redirectUri: `${window.location.origin}/`
                    });
                }
                setState({
                    user: {},
                    keycloak: null,
                    authenticated: false,
                    initialized: true,
                });
                localStorage.removeItem('user');
                localStorage.removeItem('authenticated');
            });
    };

    const login = () => {
        if (state.keycloak) {
            const searchParams = new URLSearchParams(window.location.search);
            const redirectUri = searchParams.get('redirect');
            state.keycloak.login({
                redirectUri: redirectUri ? `${redirectUri}` : window.location.origin,
            });
        }
    };

    return (
        <AuthContext.Provider value={{ ...state, setInitialized, setUser, setKeycloakState, logout, login }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
