import "./AudiobookPage.css";
import React from "react";
import Audiobook from "./Audiobook/Audiobook";
import {BookListing} from "../Books/Books";
import Ebook from "../EbookPage/Ebook/Ebook";
import {useAuth} from "../AuthContext";

const AudiobookPage = (props) => {
    let snippet = [];

    const {user } = useAuth();

    let bookListingEntries = Object.entries(BookListing);

    const isLoggedIn = !!user && !!user.username;

    if (isLoggedIn && !!user.audiobooks && user.audiobooks.length > 0) {
        for (let index = 0; index < user.audiobooks.length; index++) {
            let book = user.audiobooks[index];
            for (let bookIndex = 0; bookIndex < bookListingEntries.length; bookIndex++) {
                const [key, value] = bookListingEntries[bookIndex];
                if (book.name === value.id) {
                    snippet.push(<Audiobook
                        user={user}
                        data={value}
                    ></Audiobook>);
                }
            }
        }
    }

    return <span className={"Dashboard"}>
        {snippet}
        </span>;
}

export default AudiobookPage;
