import "./Footer.css";
import React from "react";
import AudioPlayer from "../AudioPlayer/AudioPlayer";


const Footer = (props) => {

    return <span className={"Footer-Container"}>
        <span className={"Footer"}>
            <a target={"_blank"} href={"https://www.instagram.com/novelsbysaba/"}><img src="/instagram.svg"/></a>
            <a target={"_blank"} href={"mailto:saba@novelsbysaba.com"}><img src="/message.svg"/></a>
            <a target={"_blank"} href={"https://open.spotify.com/user/d1egiar5inbskvtaaootzj10t"}><img src="/spotify.svg"/></a>
            <a target={"_blank"} href={"https://www.facebook.com/Novelsbysaba-100135891964002/"}><img src="/facebook.svg"/></a>
        </span>
        <AudioPlayer/>
    </span>;
}

export default Footer;
