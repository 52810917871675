import "./Reader.css";
import React, {useEffect, useState} from "react";
import ReaderControls from "./ReaderControls/ReaderControls";
import {Link, NavLink, useParams} from "react-router-dom";
import {BookListing} from "../Books/Books";

const Reader = (props) => {
    let {book, page} = useParams();

    if (!page) {
        page = 0;
    }

    const [url, setUrl] = useState("/novelsbysaba/books/" + book + "/" + page + ".html");

    let characters = 0;
    if (!!book) {
        let bookListingEntries = Object.entries(BookListing);
        for (let bookIndex = 0; bookIndex < bookListingEntries.length; bookIndex++) {
            const [key, value] = bookListingEntries[bookIndex];

            if (value.readEbookLink) {
                if (value.readEbookLink.indexOf(book) >= 0) {
                    characters = value.characters;
                }
            }
        }
    }

    useEffect(() => {
            var root = document.documentElement;
            if (root.className.indexOf("reading") >= 0) {

            } else {
                root.className += " reading ";
                if (page == -1) {
                    root.className += "viewing-images";
                }
            }

            setUrl("/novelsbysaba/books/" + book + "/" + page + ".html");

            fetch("/novelsbysaba/save/book/" + book + "/chapter/" + page,
                {
                    method: "GET"
                })
                .then((response) => response.json())
                .then((result) => {
                    console.log(result);
                    fetch("/novelsbysaba/me")
                        .then((response) => response.json())
                        .then((result) => {
                            if (!!result && !!result.username) {
                                props.setUser(result);
                            }
                        })
                        .catch((exception) => {
                            console.error(exception);
                        })
                })
                .catch((exception) => {
                    console.error(exception);
                });
            return () => {
                var root = document.documentElement;
                if (root.className.indexOf("reading") >= 0) {
                    root.className = root.className.replaceAll("reading", "");
                    root.className = root.className.replaceAll("viewing-images", "");
                } else {

                }
            }
        }, [book, url, page]
    );

    let readingSnippet = <iframe className={"Reader-Iframe"} src={url}></iframe>;

    if (page == -1) {
        let imagesSnippet = [];
        for (let characterIndex = 1; characterIndex <= characters; characterIndex++) {

            imagesSnippet.push(<img className={"Reader-Character-Image"} key={characterIndex}
                                    src={"/novelsbysaba/books/" + book + "/" + characterIndex + ".jpg"}></img>);
        }
        readingSnippet = <div className={"Reader-Character-Image-Container"}>
            {imagesSnippet}
        </div>;
    }

    return <span className={"Reader"}>
        <ReaderControls book={book} page={page}/>
        {readingSnippet}
    </span>;
}

export default Reader;
