import "./Header.css";
import {NavLink} from "react-router-dom";
import React, {Fragment, useEffect} from "react";
import {useAuth} from "../AuthContext";

const Header = () => {

    const {authenticated, initialized, user, login, logout } = useAuth();

    const handleLogin = () => {
        login()
    };

    const handleLogout = () => {
        logout()
    };

    useEffect(() => {
        console.log(`Authentication state changed. 
        Authenticated: ${authenticated}
        User: ${JSON.stringify(user)}
        `);
    }, [authenticated, initialized]);

    const isLoggedIn = !!user && !!user.username && authenticated;

    const showEbook = isLoggedIn && !!user.ebooks && user.ebooks.length > 0;

    const showAudiobook = isLoggedIn && !!user.audiobooks && user.audiobooks.length > 0;

    const loginSnippet = !initialized ? <Fragment /> : isLoggedIn ?
        <h1><NavLink onClick={handleLogout} to={"/logout"} className={"Header-Active"}>LOGOUT</NavLink></h1> :
        <h1><NavLink onClick={handleLogin} to={"/login"} className={"Header-Active"}>LOGIN</NavLink></h1>;


    const ebookSnippet = isLoggedIn && authenticated && showEbook ?
        <h1><NavLink to={"/ebook"} className={"Header-Active"}>EBOOK</NavLink></h1>
        :
        <Fragment />

    const audiobookSnippet = isLoggedIn && authenticated && showAudiobook ?
        <h1><NavLink to={"/audiobook"} className={"Header-Active"}>AUDIOBOOK</NavLink></h1>
        :
        <Fragment />

    return <span className={"Header"}>
        <h1><NavLink to={"/home"} className={"Header-Active"}>Home</NavLink></h1>
        <h1><NavLink to={"/about"} className={"Header-Active"}>About</NavLink></h1>
        <h1><NavLink to={"/books"} className={"Header-Active"}>Book Shop</NavLink></h1>
        {audiobookSnippet}
        {ebookSnippet}
        {loginSnippet}
        <h1 className={"Header-ReactSnap-Hidden-Link"}><NavLink to={"/email-capture"} className={"Header-Active"}>Email Capture</NavLink></h1>
        </span>;
}

export default Header;