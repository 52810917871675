import "./Error.css";
import React, {Fragment, useEffect, useState} from "react";
import {Link, NavLink, useParams} from "react-router-dom";
import {BookListing, Books} from "../Books/Books";

const knownErrors = [
    {
        type : "not-found",
        message : "Oops! We weren't sure what you asked. Here is the shop in case that's what you're looking for."
    },
    {
        type : "server",
        message : "Sorry! Something is likely wrong with our servers. Please try again later."
    },
    {
        type : "unauthorized",
        message : "It looks like you cannot access that. Here is the shop in case you wanted to buy it."
    },
    {
        type : "forbidden",
        message : "It looks like you're logged out of your e-book library. Click E-BOOK LIBRARY to login."
    }
];
const Error = (props) => {
    let {error} = useParams();
    let snippet = <Fragment />;

    let message = "Something wasn't quite right. Feel free to reach out to us for help if needed."
    if (!!error) {
        for (let index = 0; index < knownErrors.length; index++) {
            let knownError = knownErrors[index];
            if (knownError.type === error) {
                message = knownError.message;

                break;
            }
        }
    }
    snippet = <Fragment>
        <p className={"Error-Text"}>{message}</p>
        <Books user={props.user}/>
    </Fragment>;

    return <span className={"Error"}>
        {snippet}
    </span>;
}

export default Error;
