import "./Ebook.css";
import React, {Fragment} from "react";

const Ebook = (props) => {
    let data = props.data;
    const isLoggedIn = !!props.user && !!props.user.username;
    let ownsBook = false;
    let currentPage = 0;
    if (isLoggedIn && !!props.user.ebooks && props.user.ebooks.length > 0) {
        for (let index = 0; index < props.user.ebooks.length; index++) {
            let book = props.user.ebooks[index];

            if (props.data.readEbookLink) {
                if (props.data.readEbookLink.indexOf(book.name) >= 0) {
                    ownsBook = true;
                    try {
                        currentPage = parseInt(book.bookmark);
                    } catch (exception) {
                        console.error(exception);
                    }
                    break;
                }
            }
        }
    }

    let digitalSnippet = <Fragment/>;
    if (data.readEbookLink || data.buyEbookLink) {

        digitalSnippet = ownsBook ?
            <span className={"DashboardBook-Right"}>
                <a href={data.readEbookLink + "/" + currentPage}>
                    <div className={"DashboardBook-Buy"}>
                        <div>Read Online</div>
                        <div>Owned</div>
                    </div>
                </a>
            </span>
            :
            <span className={"DashboardBook-Right"}>
                <a href={data.buyEbookLink}>
                    <div className={"DashboardBook-Buy"}>
                        <div>Online eBook</div>
                        <div>Buy Now</div>
                    </div>
                </a>
            </span>;

    }

    return (<span id={data.id} className={"DashboardBook"}>
        <span>
            <img className={"DashboardBook-Cover"} src={data.coverImage}></img>
        </span>
        <span className={"DashboardBook-Text"}>
            <h2>{data.title}</h2>
            <div dangerouslySetInnerHTML={{__html: data.description}}></div>
        </span>
        <span className={"DashboardBook-Column"}>
            {digitalSnippet}
        </span>
        </span>);
}

export default Ebook;
