import "./Books.css";
import {Link, NavLink, Route} from "react-router-dom";
import React from "react";
import Book from "./Book/Book";
import book1Image from "../Images/book1.jpg";
import book2Image from "../Images/book2.jpg";
import book3Image from "../Images/book3.jpg";
import book4Image from "../Images/book4.jpg";

const BookListing = {
    book1 : {
        id: "kamande-khaterat",
        title: "Kamande Khaterat (Snare Of Memories)",
        buyLink: "https://novels-by-saba.square.site/product/kamande-khaterat/1?cp=true&sa=true&sbp=false&q=false",
        readEbookLink: "/reader/kamande-khaterat",
        /**buyEbookLink: "https://novels-by-saba.square.site/product/-ebook-kamande-khaterat-snare-of-memories-/3",**/
        listenAudiobookLink: "/listen/kamande-khaterat",
        audiobook: {
            chapters: [
                { title: '' }
            ]
        },
        chapters: 23,
        characters: 18,
        priceEbook: "$4.99",
        pricePrinted: "$24.99",
        coverImage: book1Image,
        description: "Humans beings are twisted.<br />" +
            "While they can be free, sometimes by their own volition, they're ensnared and imprisoned in the past! Their memories become permanent .... they surrender to destiny and stop fighting and in this way sometimes forget how to love and prepare for self-defeat.<br/>" +
            "In this book, we go on a journey with Gelareh's story, a girl who on the apex of youth, after an incident that she views herself as responsible, is caught living each moment in the twisted snare of past memories.<br/>" +
            "During the story, she is helped with a love that blossoms in her and by way of the people situated in her life, she learns to once again take the reigns of her life, free herself, and give herself and her heart one more chance!<br/>"
    },
    book2 : {
        id: "mikham-ashegham-bashi",
        title: "Mikham Ashegham Bashi (Want You To Love Me)",
        buyLink: "https://novels-by-saba.square.site/product/mikham-ashegham-bashi-want-you-to-love-me-/2?cp=true&sa=true&sbp=false&q=false",
        readEbookLink: "/reader/mikham-ashegham-bashi",
        /**buyEbookLink: "https://novels-by-saba.square.site/product/-ebook-mikham-ashegham-bashi-want-you-to-love-me-1-/4",**/
        chapters: 32,
        characters: 17,
        priceEbook: "$4.99",
        pricePrinted: "$24.99",
        coverImage: book2Image,
        description: "In challenging times, we need to live life to the fullest to attain happiness.<br />" +
            "Immersing oneself in life's ups and downs desensitizes them to the beautiful essence of life and they forget that love heals old wounds.<br/>" +
            "Perhaps Tara's love story has diminished from many memories, but this book retells it to rekindle that memory.<br/>" +
            "It's true that the ending point of our lives are unbeknownst to us, but we should remember that we reap what we sow!<br/>"
    },
    book3 : {
        id: "hima-yani-dokhtare-ashegh",
        title: "Hima Yani Dokhtare Ashegh (Hima Means Girl In Love)",
        buyLink: "https://novels-by-saba.square.site/product/hima-yani-dokhtare-ashegh-hima-means-girl-in-love-/5?cp=true&sa=true&sbp=false&q=false",
        pricePrinted: "$24.99",
        coverImage: book3Image,
        description: `fam·i·ly<br />
/ˈfam(ə)lē/<br />
Simple word, but as complex as the origin of life.<br />
Birth by itself is no reason for being a member. In the same physical house, people can be emotionally miles apart; even with those of the same womb; even with those who created you.<br />
Sometimes life brings people together. Fate can make strangers closer together than they are with their blood relatives.<br />
It is not one’s tribe and lineage that holds the family together. Instead, it is the values and actions of individuals. They are willing to give up everything for the sake of keeping their invisible bonds intact; even sacrifice everything for each other, including themselves.<br />
Their sense of belonging to each other closes the way to many paths, but they fight on, even with themselves.<br />
Looking at Hima’s destiny, the girl in this story, one can feel her deep feelings and attachment to a family that is not defined by DNA, but bound by friendship.<br />
As she and those around her struggle to keep each other, they clearly demonstrate how each of them overcomes their own challenges to stick together.<br />
Hima means girl in love.<br />`
    },
    book4 : {
        id: "kam-miyaramet",
        title: "Kam Miyaramet (You're Missing In My Heart)",
        buyLink: "https://novels-by-saba.square.site/product/kam-miyaramet-you-re-missing-in-my-heart-/10?cp=true&sa=true&sbp=false&q=false",
        pricePrinted: "$24.99",
        coverImage: book4Image,
        description: `<br />`
    }

};

const Books = (props) => {

    return <span className={"Books"}>
            <Book
                user={props.user}
                data={BookListing.book1}
            ></Book>
            <Book
                user={props.user}
                data={BookListing.book2}
            ></Book>
            <Book
                user={props.user}
                data={BookListing.book3}
            ></Book>
            <Book
                user={props.user}
                data={BookListing.book4}
            ></Book>
        </span>;
}

export {
    Books,
    BookListing
};

