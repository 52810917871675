import H5AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import KamandeKhateratAudio from '../Audio/1.mp3';
import React from "react";
import './AudioPlayer.css';

const AudioPlayer = () => {
    return <H5AudioPlayer className={"AudioPlayer"} src={KamandeKhateratAudio}
                          customAdditionalControls={[]}
                          showJumpControls={false}
                          showDownloadProgress={false}
                          showFilledProgress={false}
                          showFilledVolume={false}
                          showSkipControls={false}
                          loop={true}
                          volume={1.0}
    >
        </H5AudioPlayer>;
}


export default AudioPlayer;