import "./Home.css";

import {Link, NavLink, Route} from "react-router-dom";
import React, {useEffect} from "react";
import logo from "../Images/logo.png";

const Home = (props) => {
    useEffect(() => {

    })
    return <div className={"Home"}>
        <div className={"Home-Background"}></div>
        <div className={"Home-Logo"}></div>
    </div>;
}

export default Home;