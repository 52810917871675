import React, { createContext, useContext, useEffect, useState } from 'react';
import IndexedDBUtil from './IndexedDBUtil'; // Import the IndexedDB utility class

const IndexedDBContext = createContext();

export const useIndexedDB = () => useContext(IndexedDBContext);

export const IndexedDBProvider = ({ children }) => {
    const [dbUtil, setDbUtil] = useState(null);

    useEffect(() => {
        const initDB = async () => {
            const util = new IndexedDBUtil('MyDatabase', 'MP3Store');
            await util.init();
            setDbUtil(util);
        };

        initDB();
    }, []);

    return (
        <IndexedDBContext.Provider value={dbUtil}>
            {children}
        </IndexedDBContext.Provider>
    );
};
