import "./Book.css";
import React, {Fragment} from "react";

const Book = (props) => {
    let data = props.data;

    const isLoggedIn = !!props.user && !!props.user.username;
    let ownsBook = false;
    if (isLoggedIn && !!props.user.books && props.user.books.length > 0) {
        for (let index = 0; index < props.user.books.length; index++) {
            let book = props.user.books[index];
            if (props.data.buyEbookLink && props.data.buyEbookLink.indexOf(book.name) >= 0) {
                ownsBook = true;
                break;
            }
        }

    }

    let digitalSnippet = <Fragment />;
    
    // only show ebook if they own it
    if (ownsBook) {
	    if (data.readEbookLink || data.buyEbookLink) {
		digitalSnippet = !data.readEbookLink ? <Fragment /> : ownsBook ?
		    <span className={"Book-Right"}>
			<a href={data.readEbookLink}>
			    <div className={"Book-Buy"}>
				<div>Read Online</div>
				<div>Owned</div>
			    </div>
			</a>
		    </span>
		    :
		    <span className={"Book-Right"}>
			<a href={data.buyEbookLink}>
			    <div className={"Book-Buy"}>
				<div>Online eBook</div>
				<div>{data.priceEbook}</div>
				<div>Buy Now</div>
			    </div>
			</a>
		    </span>;
	    }
    }


    return <span className={"Book"}>
        <span>
            <img className={"Book-Cover"} src={data.coverImage}></img>
        </span>
        <span className={"Book-Text"}>
            <h2>{data.title}</h2>
            <div dangerouslySetInnerHTML={{__html: data.description}}></div>
        </span>
        <span className={"Book-Column"}>
            <span className={"Book-Right"}>
                <a href={data.buyLink}>
                    <div className={"Book-Buy"}>
                        <div>Printed Paperback</div>
                        <div>{data.pricePrinted}</div>
                        <div>Buy Now</div>
                    </div>
                </a>
            </span>
            {digitalSnippet}
        </span>
        </span>;
}

export default Book;
