import './BarcodeGenerator.css';
import QRCode from "qrcode.react";
import {useEffect, useRef, useState} from "react";


function BarcodeGenerator() {

    const [range, setRange] = useState({
        from: 1,
        to: 10
    });

    const fromInputRef = useRef();
    const toInputRef = useRef();
    let qrCodeTable = [];

    const downloadAll = () => {
        document.querySelectorAll("a[data-download-image]").forEach(value => {
            value.click();
        });
    }

    const downloadImage = (id) => {
        var link = document.createElement('a');
        link.download = id + ".png";
        link.href = document.getElementById(id).toDataURL()
        link.click();
    }
    console.log("From ", range.from, " To ", range.to);

    for (let index = parseInt(range.from); index <= parseInt(range.to); index++) {
        let url = "https://novelsbysaba.com/barcode-" + index;
        let id = "barcode-" + index;
        qrCodeTable.push(
            <tr key={url}>
                <td>
                    {url}
                </td>
                <td>
                    <a data-download-image onClick={() => {
                        downloadImage(id)
                    }} href={"#"}>
                        <QRCode id={id} value={url}/>
                    </a>
                </td>
            </tr>
        )
    }

    const handleFromChange = (event) => {
        console.log(event);
        console.log(fromInputRef.current);
        console.log(event.target.value);
        setRange({
            ...range,
            from: event.target.value
        });
    }

    const handleToChange = (event) => {
        console.log(event);
        console.log(fromInputRef.current);
        console.log(event.target.value);
        setRange({
            ...range,
            to: event.target.value
        });
    }

    useEffect(() => {
        document.body.classList.add("ZenMode");

        return () => {
            document.body.classList.remove("ZenMode");
	    
	}
    });

    return (
        <div className="BarcodeGenerator">
            <div className={"BarcodeGenerator-Container"}>
                <h3>Range</h3>
                <label>From</label>
                <input
                    ref={fromInputRef}
                    type={"number"}
                    onChange={handleFromChange}
                    defaultValue={range.from}
                />

                <label>To</label>
                <input
                    type={"number"}
                    onChange={handleToChange}
                    defaultValue={range.to}
                />

            </div>
            <div className={"BarcodeGenerator-Container"}>
                <button onClick={downloadAll}>Download All</button>
            </div>
            <div className={"BarcodeGenerator-Container"}>
                <table>
                    <tbody>
                    {qrCodeTable}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default BarcodeGenerator;
