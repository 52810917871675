import './EmailCapture.css';
import "../ZenFullscreen.css";
import React, {useRef} from "react";


function EmailCapture() {

    const emailInputRef = useRef();
    let timeoutId = 0;

    const autoSaveEmail = () => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(() => {
            saveEmail(false);
        }, 30000);

    }
    const saveEmail = (displayThanks = true) => {

        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        let email = emailInputRef.current.value;
        window.localStorage.setItem(email, "" + new Date().getTime());

        if (displayThanks) {
            emailInputRef.current.readOnly =true ;
            emailInputRef.current.style.color = "darkgreen";
            emailInputRef.current.value = `Thanks for your email, ${email}!`;
        }
        setTimeout(() => {
            emailInputRef.current.readOnly = false;
            emailInputRef.current.style.color = "black";
            emailInputRef.current.value = "";
        }, 3000);
    }

    return (
        <div className="EmailCapture">
            <div className={"EmailCapture-Container"}>
                <input
                    className={"EmailCapture-Input"}
                    ref={emailInputRef}
                    type={"email"}
                    placeholder={"Email"}
                    onChange={autoSaveEmail}
                />

            </div>
            <div className={"EmailCapture-Container EmailCapture-Save"}>
                <button onClick={saveEmail}>Save</button>
            </div>

        </div>
    );
}

export default EmailCapture;
